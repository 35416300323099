import supabase from "../config/supabaseClient"

// import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
// import { ViewVerifyOtp } from "@supabase/auth-ui-shared"

const Login = () => {
  
  return (
    <div className="Login">
      <h2>登录</h2>
      <Auth
      supabaseClient={supabase}
      appearance={{ theme: ThemeSupa }}
      providers={[]}
    //   view={"forgotten_password"}
    />
    </div>
  )
}

export default Login

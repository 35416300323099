import supabase from "../config/supabaseClient"
import { useState, useEffect } from "react"

const Home = () => {
  console.log(supabase)
  
  const [fetchError, setFetchError] = useState(null)
  const [alums, setAlums] = useState(null)

  useEffect(() => {
    const fetchAlums = async () => {
      const { data, error } = await supabase
        .from('users_test')
        .select()
      if (error) {
        setFetchError('Could not fetch alums')
        setAlums(null)
      }
      if (data) {
        setAlums(data)
        setFetchError(null)
      }
    }
    fetchAlums()
  }, [])

  console.log(alums)

  // var x = "admin1";
  const testFunc = async() => {
    // const { data } = await supabase.rpc("user_exists", { name: x });
    // const { data } = await supabase.rpc("query_self");
    // const { data } = await supabase.rpc("fetch_all");
    const { data } = await supabase.rpc("query_filter", {
      q_name: null,
      q_loc: null,
      q_year: null,
      q_school: null,
      q_major: null,
      q_career: null,
      q_job: null,
      qsize: 10,
      page: 1
    });
    // const { data } = await supabase.rpc("update_self", {
    //   pub_email: 'new pub email'
    // });
    console.log(data);
  }

  return (
    <div className="page home">
      <h2>Home</h2>
      <button
        onClick={testFunc}
      >
        Test Button
      </button>
      {fetchError && (<p>{fetchError}</p>)}
      {alums && (
        <div className="alums">
          {alums.map(alum => (
            <p>{alum.name_cn}</p>
          ))
          }
        </div>
      )}
    </div>
  )
}

export default Home
